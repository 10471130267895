
import {
  AnalyticFilter,
  AnalyticsState,
  AnalyticsTypes,
} from "@/store/modules/analytics/analytics.types";
import VueApexCharts from "vue-apexcharts";
import { Component, Prop, Vue } from "vue-property-decorator";
import { namespace } from "vuex-class";

const analyticX = namespace("Analytics");

@Component({
  components: {
    StatsAvatar: () => import("@/components/dashboard/StatsAvatar.vue"),
    VueApexCharts,
  },
})
export default class TicketsByPriority extends Vue {
  @analyticX.State(AnalyticsTypes.PRIORITY_ANALYTICS)
  public assigneeAnalytics!: AnalyticsState;

  @analyticX.Action(AnalyticsTypes.LOAD_PRIORITY_ANALYTICS)
  public loadPriorityStats!: (filter: AnalyticFilter) => void;

  get series(): unknown {
    return this.assigneeAnalytics.analytics.map((a) => a.count);
  }

  get options(): unknown {
    return {
      plotOptions: {
        bar: {
          borderRadius: 8,
          columnWidth: "30%",
          borderRadiusApplication: "end",
        },
      },
      legend: {
        position: "bottom",
      },
      colors: [
        "#51A89C",
        "#6E838E",
        "#F7BB4E",
        "#EE5373",
        "#8B73D7",
        "#F07A44",
        "#6EE6A6",
        "#429EF7",
      ],
      labels: this.assigneeAnalytics.analytics.map((a) => a.name),
      chart: {
        toolbar: {
          show: false,
        },
      },
    };
  }

  @Prop({ default: undefined }) public start?: string;
  @Prop({ default: undefined }) public end?: string;

  filterChanged(value: { forced?: boolean }): void {
    this.loadPriorityStats({
      start: this.start,
      end: this.end,
      force: value.forced,
    });
  }

  mounted(): void {
    this.filterChanged({});
  }
}
